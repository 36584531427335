import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";

import {
  COLORS,
  CONTAINERS,
  PADDINGS,
  SCREENS,
  SVGS
} from "../../utils/constants";


const Section = styled.section`
  width: 100%;
  color: #231F20;
`;

const Container = styled.div`
  width: ${ CONTAINERS.mobile };
  margin: 0 auto;
  margin-top: calc(2 * ${ PADDINGS.header.mobile.y } + ${ SVGS.brandLogo.height });

  @media (min-width: ${ SCREENS.tablet }) {
    width: ${ CONTAINERS.tablet };
    margin-top: calc(2 * ${ PADDINGS.header.tablet.y } + ${ SVGS.brandLogo.height });
  }

  @media (min-width: ${ SCREENS.laptop }) {
    width: ${ CONTAINERS.laptop };
    margin-top: calc(2 * ${ PADDINGS.header.laptop.y } + ${ SVGS.brandLogo.height });
  }

  @media (min-width: ${ SCREENS.desktop }) {
    margin-top: calc(2 * ${ PADDINGS.header.desktop.y } + ${ SVGS.brandLogo.height });
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    margin-top: calc(2 * ${ PADDINGS.header.largeDesktop.y } + ${ SVGS.brandLogo.height });
  }
`;

const ArticleTitle = styled.h1`
  width: 100%;
  margin: 0;

  @media (min-width: ${ SCREENS.laptop }) {
    width: 60%;
  }

  @media (min-width: ${ SCREENS.desktop }) {
    width: 60%;
  }

  @media (min-width: ${ SCREENS.largeDesktop }) {
    width: 60%;
  }
`;

const ArticlePostDetails = styled.p`
  margin-bottom: 2rem;
`;


const ArticleHero = ({
  articleTitle,
  articleSubtitle,
  articlePostDate,
  articleAuthor,
  articleImage
}) => {
  return (
    <Section>
      <Container>
        <ArticleTitle>
          { articleTitle }
        </ArticleTitle>
        { articlePostDate &&
          <ArticlePostDetails>{ articlePostDate } | { articleAuthor }</ArticlePostDetails>
        }
        <GatsbyImage
          image={ articleImage }
        />
      </Container>
    </Section>
  )
}


export default ArticleHero;