import * as React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import ArticleHero from "../components/Hero/ArticleHero";
import Layout from "../components/Layout/Layout";
import SEO from "../components/SEO/SEO";
import SliceZone from "../components/SliceZone/SliceZone";


export const query = graphql`
  query Article($uid: String) {
    allPrismicArticle(
      filter: {
        uid: {
          eq: $uid
        }
      }
    ) {
      edges {
        node {
          data {
            article_title {
              text
            }
            article_subtitle {
              text
            }
            article_author {
              text
            }
            article_post_date(formatString: "MMMM DD, YYYY")
            article_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1000
                    quality: 80
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            meta_title {
              text
            }
            meta_description {
              text
            }
            body {
              ... on PrismicArticleBodyArticleSubheading {
                primary {
                  article_subheading_text {
                    text
                  }
                }
                slice_type
              }
              ... on PrismicArticleBodyArticleParagraph {
                primary {
                  article_section_text {
                    raw
                  }
                }
                slice_type
              }
              ... on PrismicArticleBodyCaptionedImage {
                primary {
                  article_image_caption {
                    text
                  }
                  article_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
                slice_type
              }
            }
          }
        }
      }
    }
  }
`;


const ArticleTemplate = ({
  data
}) => {
  if (!data) return null;

  const document = data.allPrismicArticle.edges[0].node.data;

  const articleMeta = {
    metaTitle: document.meta_title.text,
    metaDescription: document.meta_description.text,
  };

  const articleContent = {
    articleTitle: document.article_title.text,
    articleSubtitle: document.article_subtitle.text,
    articlePostDate: document.article_post_date,
    articleAuthor: document.article_author.text,
    articleImage: getImage(document.article_image.localFile),
  };

  return (
    <Layout
      isLandingPage={ false }
      headerTextColor="#231F20"
    >
      <SEO
        title={ articleMeta.metaTitle }
        description={ articleMeta.metaDescription }
      />

      <ArticleHero
        articleTitle={ articleContent.articleTitle }
        articleSubtitle={ articleContent.articleSubtitle }
        articlePostDate={ articleContent.articlePostDate }
        articleAuthor={ articleContent.articleAuthor }
        articleImage={ articleContent.articleImage }
      />

      <SliceZone sliceZone={ document.body } />
    </Layout>
  )
}

export default ArticleTemplate;